import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
`

export const Content = styled.div`
  margin-top: 4rem;
  width: 100%;

  div[role='tabpanel'] {
    padding: 1rem 0 0 0;
  }

  div[role='tablist'] {
    padding-top: 1.5rem;
    margin: 0 1.5rem;
    max-width: calc(100vw - 3rem);
    flex-wrap: nowrap;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 120%;
      height: 100%;
      background-color: var(--neutral-theme);
      z-index: -1;
      transform: translateY(1px);
      border-bottom: 1px solid var(--gray200);
    }
  }

  div[role='tab'] {
    padding: 0.5rem 0.7rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`
