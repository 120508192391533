import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .dividerStyle {
    margin: 0 -2rem;
  }

  .filterStyle {
    margin-top: 1rem;
  }

  .errorStyle {
    width: 100%;
    height: 90vh;
  }
`

export const Header = styled.div`
  background: var(--neutral-theme);
  width: 100%;
  padding: 3.5rem 1.5rem 0;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  margin: 0 1.5rem;
  overflow: hidden;
`

export const Item = styled.div`
  flex: auto;
`

export const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
`
